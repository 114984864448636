@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}


@font-face {
    font-family: BRSonoma;
    src: url(https://d1tsa7ywxx9hjv.cloudfront.net/css/fonts/BRSonoma.otf);
}

@font-face {
    font-family: BRSonoma;
    src: url(https://integracions.laxarxames.cat/css/fonts/BRSonoma-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: BRSonoma;
    src: url(https://integracions.laxarxames.cat/css/fonts/BRSonoma-Light.otf);
    font-weight: lighter;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}


@import '_toastify';

@import '_core';



#vneo_app {
   
 
    @import '_materialize';
    @import '_custom_grid';

    width: 100%;

    .vneo-spacer {
        margin-top: 5vh; /* 5% de la altura del viewport */
        @media #{$medium-and-up}{
            margin-top: 10vh; /* 10% de la altura del viewport */
        }
        
    }

    #vneo_gateway {
        position: absolute;
        visibility: hidden;
        top: 0px;
        left: -100000px;
    }

    font-family: BRSonoma,
    Arial,
    Helvetica,
    sans-serif;

    .progress {
        background-color: grey;
    }

    .card-panel {
        margin: 0px;
        height: 100%;
    }

    .program {

        .row {
            margin: 0px;

        }

        padding:0px;
        position: relative;
        padding-bottom:0%;

        .program-description>p {
            padding-top: 25px;
            padding-bottom: 25px
        }
    }


    .thumbnail {
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;

        .cortina {
            background-color: rgba(0, 0, 0, 0.5);
            width: 50%;
            height: 100%;
            min-height: 300px;

            background: linear-gradient(90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 100%);
        }

    }

    .bc-player-0bg2pVfWb_default {

        &:hover {
            .vjs-big-play-button {
                background-color: rgba(250, 81, 75, 1);
            }
        }

        .vjs-big-play-button {
            top: 50%;
            left: 50%;
            margin-left: -1em;
            margin-top: -1em;
            width: 2em;
            height: 2em;
            line-height: 2em;
            border: none;
            border-radius: 50%;
            font-size: 3.5em;
            background-color: rgba(0, 0, 0, .45);
            color: #fff;
            -webkit-transition: border-color .4s, outline .4s, background-color .4s;
            transition-property: border-color, outline, background-color;
            transition-duration: 0.4s, 0.4s, 0.4s;
            transition-timing-function: ease, ease, ease;
            transition-delay: 0s, 0s, 0s;
            transition-behavior: normal, normal, normal;
            -moz-transition: border-color .4s, outline .4s, background-color .4s;
            -ms-transition: border-color .4s, outline .4s, background-color .4s;
            -o-transition: border-color .4s, outline .4s, background-color .4s;
            transition: border-color .4s, outline .4s, background-color .4s;
        }
    }

    .vjs-button.back-button {
        display: none;
    }





    .pulse:hover,
    .pulse:focus {
        animation: pulse 0.5s;
        box-shadow: 0 0 0 0.3em #ff9800;
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 transparent;
        }
    }





}