
#vneo_app {
    position: relative;
    width: 100%;
    //padding-bottom: 56.25%; // (9 / 16) * 100 = 56.25% ratio  16:9
    //border: 1px solid red;


    .vneo_aspect-ratio-16-9 {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%; // (9 / 16) * 100 = 56.25%
    
      .vneo_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

   



    ._vneo_unseen{
      visibility: hidden;
      position: absolute;
    }


    .row .col.vneo_no-padding {
      padding: 0px;
      margin: 0px;
    }

   

  
      

}