.program {

  .flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .flex-col {
      flex: 1;
    }

    
  }

  @media (min-width: 1024px) {
    .flex {
      flex-direction: row-reverse;
    }

  }
  

}